import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  Heading,
  Text,
  Image,
  UnorderedList,
  ListItem,
  Container,
} from '@chakra-ui/react';

import Layout from '../components/layout';

import appDataDeletionSrc from '../images/app-data-deletion.jpg';
import appDataDeletionAlertSrc from '../images/app-data-deletion-alert.jpg';
import appDataDeletionSuccessSrc from '../images/app-data-deletion-success.jpg';

const DataDeletionPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('page-title')}>
      <Container maxWidth="container.xl">
        <Heading
          as="h1"
          fontSize={{ base: 'xl', lg: '6xl' }}
          marginTop={{ base: '4rem', lg: '8rem' }}
          marginBottom={{ base: '1.8rem', lg: '3.2rem' }}
          textTransform="capitalize"
        >
          {t('user-data-deletion')}
        </Heading>
        <Text fontSize={{ base: 'sm', lg: 'md' }}>{t('delete-data-from')}</Text>
        <UnorderedList
          fontSize={{ base: 'sm', lg: 'md' }}
          marginY={{ base: '1.8rem', lg: '3.2rem' }}
        >
          <ListItem>Google</ListItem>
          <ListItem>Facebook</ListItem>
        </UnorderedList>
        <Text fontSize={{ base: 'sm', lg: 'md' }}>
          {t('follow-steps-title')}
        </Text>
        <Heading
          as="h2"
          fontSize={{ base: 'md', lg: 'xl' }}
          marginTop={{ base: '4rem', lg: '8rem' }}
          marginBottom={{ base: '1.8rem', lg: '3.2rem' }}
        >
          {t('step-0.title')}
        </Heading>
        <Image
          src={appDataDeletionSrc}
          alt="CreditCalculator App Data Deletion Screen"
          objectFit="contain"
          height="65rem"
        />
        <Heading
          as="h2"
          fontSize={{ base: 'md', lg: 'xl' }}
          marginTop={{ base: '4rem', lg: '8rem' }}
          marginBottom={{ base: '1.8rem', lg: '3.2rem' }}
        >
          {t('step-1.title')}
        </Heading>
        <Image
          src={appDataDeletionAlertSrc}
          alt="CreditCalculator App Data Deletion confirm message"
          objectFit="contain"
          height="65rem"
        />
        <Heading
          as="h2"
          fontSize={{ base: 'md', lg: 'xl' }}
          marginTop={{ base: '4rem', lg: '8rem' }}
          marginBottom="1.8rem"
        >
          {t('step-2.title')}
        </Heading>
        <Text
          fontSize={{ base: 'sm', lg: 'md' }}
          marginBottom={{ base: '1.8rem', lg: '3.2rem' }}
        >
          {t('step-2.description')}
        </Text>
        <Image
          src={appDataDeletionSuccessSrc}
          alt="CreditCalculator App Data Deletion success message"
          objectFit="contain"
          height="65rem"
          marginBottom="8rem"
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "data-deletion"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default DataDeletionPage;
